import { Grid, Tab, Tabs } from '@mui/material';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonGeneric from '../../Components/generic/ButtonGeneric';
import DatePickerGeneric from '../../Components/generic/DatePickerGeneric';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import ListaPedidosClientePage from './ListaPedidosClientePage';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ListaPedidosEmAnalise from './ListaPedidosEmAnalise';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PedidosClientePage: React.FC = () => {
  const [dataInicio, setDataInicio] = useState(dayjs().startOf('month'));
  const [dataFim, setDataFim] = useState(dayjs().endOf('month'));

  const navigate = useNavigate();

  const [sessao] = useSessao();
  const corPrincipal = sessao.empresa?.corPrincipal ?? '#1976d2';

  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      {sessao.empresa?.clientePodeFazerPedido && (
        <Grid item xs={12} style={{ textAlign: 'end' }}>
          <ButtonGeneric
            label={'Novo pedido'}
            onClick={() => navigate('/novoPedido')}
            startIcon={<AddShoppingCartIcon />}
            style={{ backgroundColor: corPrincipal, color: 'white' }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Pedidos aprovados" />
          <Tab label="Pendentes" />
        </Tabs>
      </Grid>
      <Grid item xs={6}>
        <DatePickerGeneric
          label={'Data inicial'}
          value={dataInicio}
          setValue={(value) => setDataInicio(value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DatePickerGeneric
          label={'Data final'}
          value={dataFim}
          setValue={(value) => setDataFim(value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <ListaPedidosClientePage dataInicio={dataInicio} dataFim={dataFim} />
        </TabPanel>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={1}>
          <ListaPedidosEmAnalise dataInicio={dataInicio} dataFim={dataFim} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default PedidosClientePage;
