import { Box, Typography, Link } from '@mui/material';
import LogoEdilSystem from '../../assets/edilsystem-logo.png';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '16px',
        margin: '0 0 10px 0',
        gap: 1
      }}
    >
      <Link
        href="https://www.edilsystem.com.br"
        target="_blank"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5
        }}
      >
        <Typography variant="body2" color="textSecondary">
          Copyright ©
        </Typography>
        <Box
          component="img"
          src={LogoEdilSystem}
          alt="Logotipo da EdilSystem"
          sx={{
            width: 45,
            height: 'auto'
          }}
        />
        <Typography variant="body2" color="textSecondary">
          {new Date().getFullYear()}.
        </Typography>
      </Link>
    </Box>
  );
};

export default Footer;
