import { useEffect, useRef, useState } from 'react';
import { ParcelaPedidoCompletoType } from './types/PedidoTypes';
import { DadosListagemContasReceberCliente } from './types/ContaReceberTypes';
import { DUPR_STATUS, DUPR_STATUS_CONCLUIDO } from './constants';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import MiniInfo from './Components/generic/MiniInfo';
import { colors } from './theme';
import { toast, ToastPosition } from 'react-toastify';

export const formatarDinheiro = (value: number | string) => {
  if (!value) {
    return '0,00';
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  value = value.toFixed(2);
  value = value.replace('.', ',');
  if (value.length > 6) {
    value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }
  return value;
};

export const formatarData = (data: number | string | undefined) => {
  if (!data) {
    return '';
  }
  if (typeof data === 'number') {
    data = data.toString();
  }
  data = data.replace(/-/g, '');
  data = data.slice(6, 8) + '/' + data.slice(4, 6) + '/' + data.slice(0, 4);
  return data;
};

export const formatarHora = (hora: number | string) => {
  if (!hora) {
    return '0';
  }
  if (typeof hora === 'number') {
    hora = hora.toString();
  }
  hora = hora.slice(0, 2) + ':' + hora.slice(2, 4);
  return hora;
};

export const cpfMask = (value: number | string) => {
  if (!value) {
    return '';
  }
  if (typeof value !== 'string') {
    value = value.toString();
  }
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const cnpjMask = (value: number | string) => {
  if (!value) {
    return '';
  }
  if (typeof value !== 'string') {
    value = value.toString();
  }
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const hexWithAlpha = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export function mudarIluminacao(hex: string, lum: number) {
  // lum > 0 == mais claro
  // lum < 0 == mais escuro

  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 3) {
    return '#000';
  }
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match

  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
// Hook
export function useLocalStorage<T>(key: string, initialValue: T): [T, (obj: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error: any) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error: any) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function usePageStorage<T>(
  page: string,
  key: string,
  initialValue: T
): [T, (obj: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const pageStr = window.localStorage.getItem(page);
      const pageParsed = pageStr ? JSON.parse(pageStr) : {};

      const item = pageParsed[key];

      return item ? item : initialValue;
    } catch (error: any) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);

      // Save to local storage
      const pageStr = window.localStorage.getItem(page);
      const pageParsed = pageStr ? JSON.parse(pageStr) : {};
      pageParsed[key] = valueToStore;
      window.localStorage.setItem(page, JSON.stringify(pageParsed));
    } catch (error: any) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function getItemPageStorage<T>(page: string, key: string): T {
  const pageStr = window.localStorage.getItem(page);
  const pageParsed = pageStr ? JSON.parse(pageStr) : {};
  const item = pageParsed[key];
  return item;
}

/**
 * Funciona da mesma forma que o useEffect, mas não
 * é chamado na renderização inicial, apenas nos updates.
 *
 * @date 2020-12-31
 * @param {any} effect:React.EffectCallback
 * @param {any} deps?:React.DependencyList|undefined
 * @returns {any}
 */
export const useUpdateEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
     
  }, deps);
};

export function parseData(data: string | number) {
  if (!data) {
    return '';
  }
  data = data.toString().replace(/\//g, '');
  data = data.padStart(8, '0');
  return data.slice(6, 8) + '/' + data.slice(4, 6) + '/' + data.slice(0, 4);
}

export function parseIntMelhorado(n: string) {
  if (!n) {
    return 0;
  }
  const value = parseInt(n.replace(/\D/g, ''));
  return isNaN(value) ? 0 : value;
}

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};

export function desinverteData(data: string | number) {
  if (!data) {
    return '';
  }
  data = data.toString().replace(/\//g, '');
  data = data.padStart(8, '0');
  return data.slice(6, 8) + data.slice(4, 6) + data.slice(0, 4);
}

export function inverteData(data: string | number) {
  if (!data) {
    return '';
  }
  data = data.toString().replace(/\//g, '');
  data = data.padStart(8, '0');
  return data.slice(4, 8) + data.slice(2, 4) + data.slice(0, 2);
}

export const getTipoStatus = (
  conta: DadosListagemContasReceberCliente | ParcelaPedidoCompletoType
) => {
  const status = DUPR_STATUS.find((s) => s.codigo === conta.status);
  const hoje = dayjs().format('YYYYMMDD');
  const vencido =
    status &&
    status.codigo !== DUPR_STATUS_CONCLUIDO &&
    parseInt(conta.vencimento) < parseInt(hoje);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {status && !vencido && (
        <Typography>
          <MiniInfo color={status.cor}>
            {status.descricao}
            {Boolean(conta.dataPagamento) && (
              <>
                <br />
                {parseData(conta.dataPagamento)}
              </>
            )}
          </MiniInfo>
        </Typography>
      )}

      {vencido && (
        <MiniInfo style={{ marginTop: 3 }} color={colors.red}>
          VENCIDO
        </MiniInfo>
      )}
    </div>
  );
};

export const mostrarToast = (
  msg: string,
  severity: 'error' | 'success' | 'warning',
  position?: ToastPosition
) => {
  const options = {
    position: position ?? 'top-right'
  };

  if (severity === 'error') {
    toast.error(msg, options);
  } else if (severity === 'success') {
    toast.success(msg, options);
  } else if (severity === 'warning') {
    toast.warning(msg, options);
  }
};

export function isMobile() {
  return window.matchMedia('(max-width: 768px)').matches;
}

export function copyToClipboard(text: string) {
  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error('Erro ao copiar texto:', err);
    });
  } else {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Erro ao copiar texto:', err);
    }
    document.body.removeChild(textarea);
  }
}
