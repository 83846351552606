import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import LoginPage from '../../Pages/LoginPage/LoginPage';
import Header from './Header';
import Drawer from './Drawer';
import { Hidden, Toolbar } from '@mui/material';
import Footer from '../Menu/Footer';
import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  const [open, setOpen] = useState(false);
  const [autorizado, setAutorizado] = useState(true);
  const [sessao] = useSessao();

  useEffect(() => {
    if (!sessao || !sessao.cliente || !sessao.empresa) {
      setAutorizado(false);
    } else {
      setAutorizado(true);
    }
  }, [sessao]);

  if (!autorizado) {
    return <LoginPage />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <Header setOpen={setOpen} />

      <Drawer open={open} setOpen={setOpen} />

      <Hidden mdDown>
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '90vh'
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
        <Footer />
      </Hidden>
      <Hidden mdUp>
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0px 15px 0px 15px',
            minHeight: '90vh'
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
        <Footer />
      </Hidden>
    </Box>
  );
}
